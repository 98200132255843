/**
 * 获取url中参数值
 * @param {*} name 参数名
 */
function getParam(name) {
	var value = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [,
		""])[
		1].replace(/\+/g, '%20')) || null;
	return value;
}
function isFromWeiXin() {

        var ua = navigator.userAgent.toLowerCase();

        if (ua.match(/MicroMessenger/i) == "micromessenger") {

            return true;

        }

        return false;

    }
export default {
	getParam,
	isFromWeiXin
}
